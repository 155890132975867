import "./TxCard.css";
import React, { useState, memo, useMemo } from "react";
import Card, { CardProps } from "@mui/material/Card";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import copyToClipboard from "utils/copyToClipboard";
import { BlockchainExplorer } from "utils/explorerLinks";
import DeactiveSwipe from "../../assets/Icons/icon-swipe-deactive.svg";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import Divider, { DividerProps } from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { Tooltip, Fade } from "@mui/material";
import { REACT_APP_DEVNET } from "conf";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  NetworkName,
  CwebTransactionGql,
  Transaction,
} from "../../generated/graphql";
import {
  NetworkName as devNetworkName,
  CwebTransactionGql as devCwebTransactionGql,
  Transaction as devTransaction,
} from "../../generated/graphql-devnet";
import { BlockChainTicker } from "../TableContainer/CustomizedMui";
import { useL1BlockInfoFromClaimLazyQuery } from "../../generated/graphql-wallet";
import { walletClient } from "../../graphqlClients";

type CwebOrL1Transaction =
  | {
      cweb: CwebTransactionGql | devCwebTransactionGql;
    }
  | {
      tx: Transaction | devTransaction;
    };

type TxCardProps = {
  title: string;
  data: CwebOrL1Transaction[];
  unit: BlockChainTicker | "USD";
  width: number;
  blockchain: NetworkName | devNetworkName;
  height: number;
};

/**  MUI TxCard Card Styling  **/
const TxMuiCard = styled(Card)<CardProps>(() => ({
  height: "100%",
  boxShadow: "none",
  backgroundImage: "linear-gradient(to bottom, #353763, #272852) !important",
  borderRadius: "6px",
  textIndent: "0px",
  backgroundColor: "transparent",
}));

/**  MUI TxCard CardContent Styling  **/
const TxMuiCardContent = styled(CardContent)<CardContentProps>(() => ({
  padding: "0px 20px !important",
  height: "100%",
}));

/**  MUI TxCard CardContent Styling  **/
const TxDivider = styled(Divider)<DividerProps>(() => ({
  backgroundColor: "#4f4f72 !important",
  height: "1px !important",
  borderBottomWidth: "0px !important",
  width: "100% !important",
  marginLeft: "0px !important",
  marginRight: "0px !important",
}));

// Information required to fetch a particular l2 transaction
// TODO: This is a temporary workaround for graphql not returning this info
type L2TxFetchInfo = {
  l2txid: string;
  network: NetworkName | devNetworkName;
  height: number;
};

type ClickableTxIdProps<T> = {
  width: number;
  transaction: T | undefined;
  // TODO: These fields are just required to fetch the l1 tx id on demand
  l2tx?: L2TxFetchInfo;
  blockchain: NetworkName | devNetworkName;
};

function dataToL1Tx(data: any, l2txid: string): string | undefined {
  const l2_txs = data?.shard?.fetchClaim?.body?.content?.l2_txs;

  const ret = Object.entries(l2_txs).flatMap(([key, value]: [any, any]) =>
    value?.cweb_txid === l2txid ? [key] : []
  );

  if (ret.length === 1) {
    return ret[0];
  }
  return undefined;
}

const ClickableTxId = ({
  width,
  transaction,
  l2tx,
  blockchain,
}: ClickableTxIdProps<
  Transaction | CwebTransactionGql | devCwebTransactionGql | devTransaction
>) => {
  const [showCopy, setShowCopy] = React.useState(false);

  function handleTxIds(txid: string) {
    if (txid) {
      const txidstr = txid.substring(0, 10) + "...";
      return txidstr;
    } else return "N/A";
  }

  const [getL1BlockInfo, { loading, data }] = useL1BlockInfoFromClaimLazyQuery({
    client: walletClient,
  });

  const handleFetchL1TxidClick = () => {
    if (l2tx) {
      const { network, height } = l2tx;
      const myNetwork = network as NetworkName;
      getL1BlockInfo({
        variables: {
          network: myNetwork,
          height,
        },
      });
    } else {
      console.log("internal error in handleFetchL2TxIdClick");
    }
  };

  const openNewTab = () => {
    let url;
    if (!l2tx) {
      if (
        blockchain === NetworkName.Ethereum ||
        blockchain === NetworkName.EthereumTestnet ||
        blockchain === NetworkName.Bnb ||
        blockchain === NetworkName.BnbTestnet ||
        blockchain === NetworkName.Polygon ||
        blockchain === NetworkName.PolygonTestnet
      )
        url = `${BlockchainExplorer[blockchain]}0x${transaction?.txid}`;
      else url = `${BlockchainExplorer[blockchain]}${transaction?.txid}`;
    } else {
      const devnet = REACT_APP_DEVNET === "true";
      if (devnet) url = `${BlockchainExplorer.COINWEB_DEVNET}${l2tx.l2txid}`;
      else url = `${BlockchainExplorer.COINWEB}${l2tx.l2txid}`;
    }

    window.open(url, "_blank");
  };
  const l1txid = useMemo(() => {
    if (data && l2tx) {
      const { l2txid } = l2tx;
      const l1tx = dataToL1Tx(data, l2txid);
      return l1tx;
    }
  }, [data, l2tx]);
  return (
    <div
      onMouseLeave={() => setShowCopy(false)}
      className={width > 425 ? "Slider-data-cell" : "Slider-data-cell-mobile"}
    >
      <Typography className="BoldFont16" align="center">
        Transaction ID
      </Typography>
      <Typography className="BoldFont14" align="center">
        {transaction ? (
          <>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={
                <div id="TxId-tooltip">
                  {showCopy ? "Copied" : transaction.txid}
                </div>
              }
            >
              <div
                onClick={() => {
                  openNewTab();
                }}
                id="TxId-container"
              >
                {" "}
                {handleTxIds(transaction.txid)}
              </div>
            </Tooltip>
          </>
        ) : (
          "N/A"
        )}
      </Typography>
      {l2tx ? (
        <>
          <Typography className="BoldFont16" align="center">
            L1 Transaction ID
          </Typography>
          <Typography className="BoldFont14" align="center">
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={
                <div id="TxId-tooltip">{loading ? "Loading..." : l1txid}</div>
              }
            >
              <span onClick={handleFetchL1TxidClick} id="TxId-container-fetch">
                {l1txid ? " " : "N/A"}
              </span>
            </Tooltip>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={
                <span id="TxId-tooltip">{loading ? "Loading..." : l1txid}</span>
              }
            >
              <span
                onClick={() => {
                  if (l1txid) {
                    copyToClipboard(l1txid, setShowCopy);
                  }
                }}
                id="TxId-container"
              >
                {" "}
                {!l1txid ? " " : handleTxIds(l1txid)}
              </span>
            </Tooltip>
          </Typography>
        </>
      ) : null}
    </div>
  );
};

function TxCard(props: TxCardProps) {
  const { title, unit, width, blockchain, height: blockchainHeight } = props;
  const [currData, setCurrData] = useState(0);
  const unknownTransaction: CwebOrL1Transaction | undefined =
    props.data[currData];
  const rawContent: string | undefined =
    unknownTransaction && "cweb" in unknownTransaction
      ? unknownTransaction.cweb.rawContent
      : undefined;
  const transaction:
    | Transaction
    | CwebTransactionGql
    | devCwebTransactionGql
    | devTransaction
    | undefined =
    unknownTransaction && "tx" in unknownTransaction
      ? unknownTransaction.tx
      : unknownTransaction && "cweb" in unknownTransaction
      ? unknownTransaction.cweb
      : undefined;
  const isCwebTransaction = unknownTransaction && "cweb" in unknownTransaction;

  const len = props.data.length;
  const [rawCopy, setRawCopy] = React.useState(false);
  const ChainName =
    blockchain !== "BITCOIN_CASH"
      ? blockchain.charAt(0).toUpperCase() + blockchain.slice(1).toLowerCase()
      : "Bitcoin Cash";
  const infoText =
    unit === "USD"
      ? `These are Coinweb transactions included in the block. Coinweb transactions are embedded inside native ${ChainName} transactions, but are ignored by the ${ChainName} network. These transactions are executed by the Coinweb network.`
      : `These are native ${ChainName} transactions that are included in the block.  These transactions are executed by the ${ChainName} network directly.`;

  const handleTxAmount = () => {
    if (transaction?.amountTransacted) {
      const value =
        unit === "USD"
          ? transaction.amountTransacted / 1150
          : transaction.amountTransacted;
      const decimals = unit === "USD" ? 2 : 3;
      const amount = unit + " " + value.toFixed(decimals);
      return amount;
    } else return "N/A";
  };

  const nextSlide = () => {
    setCurrData(currData === len - 1 ? len - 1 : currData + 1);
  };

  const prevSlide = () => {
    setCurrData(currData === 0 ? 0 : currData - 1);
  };

  return (
    <div
      className="Txcard-container"
      style={{
        marginBottom: width > 425 ? "0px" : "20px",
        marginTop: width > 425 ? "0px" : undefined,
        height: "100%",
      }}
    >
      <TxMuiCard sx={{ minWidth: 100 }}>
        <TxMuiCardContent sx={{ minWidth: 100 }}>
          <div id="Carousel" style={{ textIndent: "0px !important" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className="Carousel-inner" style={{ height: "100%" }}>
                <div className="Carousel-icon-container" onClick={prevSlide}>
                  <div className="Carousel-icon">
                    <img
                      src={
                        currData === 0 || transaction === undefined
                          ? DeactiveSwipe
                          : ActiveSwipe
                      }
                      alt="swipe"
                    ></img>
                  </div>
                </div>

                <div className="Center-container">
                  <div className={width > 425 ? "Center" : "Center-mobile"}>
                    <div id="Slider-header">
                      <div id="TxCard-header">
                        <Typography className="BoldFont16" align="center">
                          {title}
                        </Typography>
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#4f4f72 !important",
                                padding: "0px !important",
                                marginTop: "-1px !important",
                              },
                            },
                          }}
                          disableFocusListener
                          TransitionComponent={Fade}
                          title={<div id="Info-tooltip">{infoText}</div>}
                        >
                          <InfoOutlinedIcon id="Info_icon" />
                        </Tooltip>
                        {title === "Coinweb Transactions" ? (
                          <div
                            onMouseLeave={() => setRawCopy(false)}
                            id="RawContent-container"
                          >
                            {rawContent ? (
                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#4f4f72 !important",
                                      padding: "0px !important",
                                      marginTop: "-1px !important",
                                    },
                                  },
                                }}
                                disableFocusListener
                                TransitionComponent={Fade}
                                title={
                                  <div id="TxId-tooltip">
                                    {rawCopy
                                      ? "Copied"
                                      : rawContent.substring(0, 25) + "..."}
                                  </div>
                                }
                              >
                                <div
                                  onClick={() => {
                                    copyToClipboard(
                                      JSON.stringify(
                                        JSON.parse(rawContent),
                                        null,
                                        4
                                      ),
                                      setRawCopy
                                    );
                                  }}
                                  id="TxId-container"
                                >
                                  <button
                                    type="button"
                                    id="RawContent-button"
                                    style={{
                                      color: "#067dfb",
                                    }}
                                  >
                                    Raw Content
                                  </button>
                                </div>
                              </Tooltip>
                            ) : (
                              <button
                                type="button"
                                id="RawContent-button"
                                style={{
                                  color: "#d3d3ef66",
                                }}
                                disabled={true}
                              >
                                Raw Content
                              </button>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <Typography className="BoldFont16-dark" align="center">
                        {transaction
                          ? String(currData + 1) + " / " + String(len)
                          : "0 / 0"}
                      </Typography>
                    </div>
                    <TxDivider variant="middle" />

                    <div className="Slider-data-container">
                      <div
                        className={
                          width > 425
                            ? "Slider-data-table"
                            : "Slider-data-table-mobile"
                        }
                      >
                        <div
                          className={
                            width > 425
                              ? "Slider-data-cell"
                              : "Slider-data-cell-mobile"
                          }
                        >
                          <Typography className="BoldFont16" align="center">
                            Transaction Amount
                          </Typography>
                          <Typography className="BoldFont14" align="center">
                            {transaction ? handleTxAmount() : "N/A"}
                          </Typography>
                        </div>
                        <ClickableTxId
                          width={width}
                          transaction={transaction}
                          l2tx={
                            isCwebTransaction && transaction
                              ? {
                                  l2txid: transaction.txid,
                                  network: blockchain,
                                  height: blockchainHeight,
                                }
                              : undefined
                          }
                          blockchain={blockchain}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Carousel-icon-container" onClick={nextSlide}>
                  <div className="Carousel-icon">
                    <img
                      className="Right-icon-img"
                      src={
                        currData === len - 1 || transaction === undefined
                          ? DeactiveSwipe
                          : ActiveSwipe
                      }
                      alt="swipe"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TxMuiCardContent>
      </TxMuiCard>
    </div>
  );
}

export default memo(TxCard);
