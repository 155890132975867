export default function copyToClipboard(
  text: string,
  onChange: (value: boolean) => void
) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
    onChange(true);
  } else {
    alert(text);
  }
}
