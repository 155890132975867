import * as React from "react";
import { BlockFragment } from "../../generated/graphql";
import { NetworkName } from "../../generated/graphql";
import { BlockFragment as devBlockFargment } from "../../generated/graphql-devnet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Fade,
} from "@mui/material";
import Row from "./Row";
// import Slide from '@mui/material/Slide';

type TableDataProps = {
  width: number;
  data: BlockFragment[] | devBlockFargment[];
  onSetSubscription: (value: boolean) => void;
  setNetwork?: (n: NetworkName | undefined) => void;
};

function TableData(props: TableDataProps) {
  const { width, data, onSetSubscription, setNetwork } = props;
  const [openHash, setOpenHash] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (setNetwork && data && data[0]?.network) {
      setNetwork(data[0]?.network as unknown as NetworkName);
    }
  }, [data, setNetwork]);
  //****************TABLE DATA********//
  return (
    <div>
      <TableContainer id="Block-Data-Table-Mui">
        <Table sx={{ borderColor: "red" }} aria-label="collapsible table">
          <TableHead>
            {width > 425 ? (
              <TableRow>
                <TableCell
                  className="Table-Header"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  Blockchain
                </TableCell>
                <TableCell
                  className="Table-Header"
                  sx={{ width: "14%" }}
                  align="center"
                >
                  Height
                </TableCell>
                <TableCell
                  sx={{
                    width: "18%",
                    paddingLeft:
                      width > 900 ? "41px!important" : "21px!important",
                  }}
                  className="Table-Header"
                  align="left"
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{ width: "13%" }}
                  className="Table-Header"
                  align="left"
                >
                  Size&nbsp;(kB)
                </TableCell>
                <TableCell
                  sx={{ width: "13%" }}
                  className="Table-Header"
                  align="left"
                >
                  <Tooltip
                    TransitionComponent={Fade}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#4f4f72 !important",
                          padding: "0px !important",
                          marginTop: "5px !important",
                          marginLeft:
                            width > 1280
                              ? "-19px !important"
                              : "0px !important",
                        },
                      },
                    }}
                    placement="bottom"
                    title={
                      <div className="Tool-hash-container">Coinweb / Total</div>
                    }
                  >
                    <div id="Wrap">Transactions</div>
                  </Tooltip>
                </TableCell>
                <TableCell
                  sx={{ width: "22%", paddingLeft: "42px!important" }}
                  className="Table-Header"
                  align="left"
                >
                  Amount Transacted
                </TableCell>
                <TableCell
                  sx={{ width: "10%" }}
                  className="Table-Header"
                  align="center"
                >
                  Block Hash
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell
                  className="Table-Header"
                  sx={{ width: "10%" }}
                  align="center"
                >
                  Blockchain
                </TableCell>
                <TableCell
                  className="Table-Header"
                  sx={{
                    width: "14%",
                    paddingLeft: "28px !important",
                  }}
                  align="center"
                >
                  Height
                </TableCell>
                <TableCell
                  className="Table-Header"
                  sx={{ paddingLeft: " 29px!important" }}
                  align="left"
                >
                  Time
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <>
              {row && (
                <Row
                  setOpenHash={setOpenHash}
                  openHash={openHash}
                  width={width}
                  key={row.hash}
                  row={row}
                  index={index}
                  rowHash={row.hash}
                  onSetSubscription={onSetSubscription}
                />
              )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableData;
