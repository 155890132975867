import Logo from "../../assets/Logos/logo_coinweb.svg";
import MenuIcon from "../../assets/Icons/menu.svg";
import { useSearchParams } from "react-router-dom";
import CloseMenuIcon from "../../assets/Icons/close-menu.svg";
import "./NavBar.css";
import React, { useState } from "react";
import Telegram from "../../assets/Icons/telegram.svg";
import Youtube from "../../assets/Icons/youtube.svg";
import Twitter from "../../assets/Icons/twitter.svg";
import Facebook from "../../assets/Icons/facebook.svg";
import Instagram from "../../assets/Icons/instagram.svg";
import LinkedIn from "../../assets/Icons/l-inked-in.svg";

const Headers = [
  // {
  //   headerName: "HOME",
  //   link: "https://coinweb.io",
  //   index: 0,
  // },
  // {
  //   headerName: "DISCOVER",
  //   link: "https://coinweb.io/discover/",
  //   index: 1,
  // },
  // {
  //   headerName: "COMMUNITY",
  //   link: "https://coinweb.io/community/",
  //   index: 2,
  // },
  // {
  //   headerName: "DOCUMENTS",
  //   link: "https://coinweb.io/documents/",
  //   index: 3,
  // },
  // {
  //   headerName: "STAKING",
  //   link: "https://staking.coinweb.io",
  //   index: 4,
  // },
  // {
  //   headerName: "EXPLORER",
  //   link: "",
  //   index: 5,
  // },
  // {
  //   headerName: "JOBS",
  //   link: "https://coinweb.io/jobs/",
  //   index: 6,
  // },
  {
    headerName: "Coinweb.io",
    // link: "https://coinweb.io/wallet/",
    link: "https://coinweb.io",
    index: 0,
  },
];

const Icons = [
  {
    icon: Telegram,
    link: "https://t.me/coinweb",
    alt: "telegram",
  },
  {
    icon: Youtube,
    link: "https://www.youtube.com/channel/UCPpfLBsOO7XDB1zeFUzIxZA",
    alt: "youtube",
  },
  {
    icon: Twitter,
    link: "https://twitter.com/coinwebofficial/",
    alt: "twitter",
  },
  {
    icon: Facebook,
    link: "https://www.facebook.com/Coinweb.io/",
    alt: "facebook",
  },
  {
    icon: Instagram,
    link: "https://www.instagram.com/coinweb.io/ ",
    alt: "instagram",
  },
  {
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/coinweb/",
    alt: "linkedIn",
  },
];

type NavBarProps = {
  setOpen: (x: boolean) => void;
  width: number;
  show: Boolean;
};

const NavBar = (props: NavBarProps) => {
  const { setOpen, width, show } = props;
  const [menu, setMenu] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const handleMenu = React.useCallback(() => {
    setOpen(!menu);
    setMenu(!menu);
  }, [setOpen, setMenu, menu]);
  return (
    <>
      {width < 1050 ? (
        <div
          id={menu ? "NavBar-wrapper-mobile-open" : "NavBar-wrapper-mobile"}
          className={show ? undefined : "Navbar-mobile-scroll"}
        >
          <div
            id="NavBar-inner-mobile"
            onClick={() => {
              setSearchParams({
                hash: "",
              });
            }}
          >
            <div id="Navbar-logo-mobile">
              <img
                id="Navbar-logo-img-mobile"
                src={Logo}
                className="navbar-logo"
                alt="logo"
              />
            </div>
            <div id="Navbar-menu-icon" onClick={handleMenu}>
              <img
                src={menu ? CloseMenuIcon : MenuIcon}
                className="navbar-logo"
                alt="logo"
              />
            </div>
          </div>
          {menu ? (
            <div id="Navbar-menu-open">
              <div>
                <ul id="NavBar-menu-social">
                  {Headers.map((header, index) => (
                    <li key={header.headerName} id="Navbar-mobile-li">
                      <a
                        className={
                          header.index === 5 ? "active-mobile" : "normal-mobile"
                        }
                        href={header.link}
                      >
                        {header.headerName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div id="Navbar-icon-container">
                <ul id="Navbar-icon-ul">
                  {Icons.map((icon) => (
                    <li key={icon.alt} style={{ height: "fit-content" }}>
                      <a href={icon.link}>
                        <img
                          style={{ width: "24px" }}
                          src={icon.icon}
                          alt={icon.alt}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div id="NavBar-container">
          <div id={show === true ? "NavBar-wrapper" : "NavBar-wrapper-scroll"}>
            <div id="NavBar-logo">
              <img
                id={show === true ? undefined : "NavBar-container-scroll"}
                src={Logo}
                className="navbar-logo"
                alt="logo"
              />
            </div>
            <div id={show === true ? "NavBar-list" : "NavBar-list-scroll"}>
              <ul id="NavBar-social">
                {Headers.map((header, index) => (
                  <li key={header.headerName}>
                    <a
                      className={header.index === 5 ? "active" : "normal"}
                      href={header.link}
                    >
                      {header.headerName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(NavBar);
