import { memo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Typography from "@mui/material/Typography";
import { jsonToYaml } from "utils/jsonToYaml";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type ClaimDataCardProps = {
  data: any;
};

const ClaimDataCard = (props: ClaimDataCardProps) => {
  const { data } = props;
  function Row(row: any) {
    const [open, setOpen] = useState(false);
    const opType = Object.keys(row)[0];
    const yaml = jsonToYaml(Object.values(row)[0]);
    const yamlLines = yaml.split("\n");

    const handleLineClick = (line: string) => {
      navigator.clipboard
        .writeText(line)
        .then(() => {
          toast.success("Copied to clipboard");
        })
        .catch((err) => {
          toast.error("Failed to copy");
          console.error("Failed to copy: ", err);
        });
    };

    return (
      <div style={{ width: "100%" }}>
        <TableCell
          onClick={() => setOpen(!open)}
          className="operationCollapseCell"
        >
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BoldBlueFont16"> {opType} Details</Typography>
        </TableCell>
        <TableCell className="operationCollapseCell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ overflowWrap: "anywhere" }}>
              <pre style={{ whiteSpace: "pre", textAlignLast: "start" }}>
                {yamlLines.map((line: string, index: number) => (
                  <code
                    key={index}
                    style={{
                      overflowWrap: "anywhere",
                      textWrap: "wrap",
                      cursor: "pointer",
                    }}
                    className="BoldFont14"
                    onClick={() => handleLineClick(line)}
                  >
                    {
                      line.match(/^\s+/) // Check if line starts with whitespace
                        ? `${line}\n` // Keep existing indentation and add newline
                        : `  ${line}\n` // Add two spaces for nested items
                    }
                  </code>
                ))}
              </pre>
            </div>
          </Collapse>
        </TableCell>
      </div>
    );
  }

  return (
    <div>
      <Typography className="BoldBlueFont16">Operations</Typography>

      {data.map((op: any) => {
        return Row(op);
      })}
    </div>
  );
};

export default memo(ClaimDataCard);
