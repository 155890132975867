import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Divider, { DividerProps } from "@mui/material/Divider";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Paper, { PaperProps } from "@mui/material/Paper";
import Button, { ButtonProps } from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import { styled } from "@mui/material/styles";
import CwebIcon from "../../assets/Icons/Networks/cweb.png";
import BtcIcon from "../../assets/Icons/Networks/bitcoin.svg";
import BtcCashIcon from "../../assets/Icons/Networks/bitcoin-cash.png";
import LiteIcon from "../../assets/Icons/Networks/lite.svg";
import EthIcon from "../../assets/Icons/Networks/ethereum.svg";
import EgldIcon from "../../assets/Icons/Networks/elrond.svg";
import PolyIcon from "../../assets/Icons/Networks/polygon.svg";
import BNBIcon from "../../assets/Icons/Networks/bnb.png";
import KujiIcon from "../../assets/Icons/Networks/kuji.png";
import ArbIcon from "../../assets/Icons/Networks/arb.png";

import { NetworkName } from "../../generated/graphql";
import { NetworkName as devNetworkName } from "../../generated/graphql-devnet";

//**** TABLE CONTAINER COMPONENTS */
export type BlockChainTicker =
  | "BTC"
  | "BCH"
  | "tBCH"
  | "tBTC"
  | "ETH"
  | "tETH"
  | "LTC"
  | "tLTC"
  | "EGLD"
  | "tEGLD"
  | "BNB"
  | "tBNB"
  | "MATIC"
  | "tMATIC"
  | "DOGE"
  | "tDOGE"
  | "KUJI"
  | "tKUJI"
  | "ARB"
  | "tARB"
  | "devnetA"
  | "devnetB";

export type BlockChain = {
  networkName: NetworkName | devNetworkName;
  label: BlockChainTicker;
  hover: string;
  currency: string;
  icon: string;
};

export const BlockChains: {
  [key in devNetworkName | NetworkName]: BlockChain;
} = {
  BITCOIN: {
    networkName: NetworkName.Bitcoin,
    label: "BTC",
    hover: "Bitcoin",
    currency: "BTC",
    icon: BtcIcon,
  },
  BITCOIN_CASH: {
    networkName: NetworkName.BitcoinCash,
    label: "BCH",
    hover: "Bitcoin Cash",
    currency: "BCH",
    icon: BtcCashIcon,
  },
  BITCOIN_CASH_TESTNET: {
    networkName: NetworkName.BitcoinCashTestnet,
    label: "tBCH",
    hover: "Bitcoin Cash Testnet",
    currency: "BCH",
    icon: BtcCashIcon,
  },
  BITCOIN_TESTNET: {
    networkName: NetworkName.BitcoinTestnet,
    label: "tBTC",
    hover: "Bitcoin Testnet",
    currency: "BTC",
    icon: BtcIcon,
  },
  LITECOIN: {
    networkName: NetworkName.Litecoin,
    label: "LTC",
    hover: "Litecoin",
    currency: "LTC",
    icon: LiteIcon,
  },
  LITECOIN_TESTNET: {
    networkName: NetworkName.LitecoinTestnet,
    label: "tLTC",
    hover: "Litecoin Testnet",
    currency: "LTC",
    icon: LiteIcon,
  },
  DOGECOIN: {
    networkName: NetworkName.Dogecoin,
    label: "DOGE",
    hover: "Dogecoin",
    currency: "DOGE",
    icon: LiteIcon,
  },
  DOGECOIN_TESTNET: {
    networkName: NetworkName.DogecoinTestnet,
    label: "tDOGE",
    hover: "Dogecoin Testnet",
    currency: "DOGE",
    icon: LiteIcon,
  },
  ETHEREUM: {
    networkName: NetworkName.Ethereum,
    label: "ETH",
    hover: "Ethereum",
    currency: "ETH",
    icon: EthIcon,
  },
  ETHEREUM_TESTNET: {
    networkName: NetworkName.EthereumTestnet,
    label: "tETH",
    hover: "Ethereum Testnet",
    currency: "ETH",
    icon: EthIcon,
  },

  ELROND: {
    networkName: NetworkName.Elrond,
    label: "EGLD",
    hover: "MultiversX",
    currency: "EGLD",
    icon: EgldIcon,
  },
  ELROND_TESTNET: {
    networkName: NetworkName.ElrondTestnet,
    label: "tEGLD",
    hover: "MultiversX Testnet",
    currency: "BCH",
    icon: EgldIcon,
  },
  BNB: {
    networkName: NetworkName.Bnb,
    label: "BNB",
    hover: "Binance",
    currency: "BNB",
    icon: BNBIcon,
  },
  BNB_TESTNET: {
    networkName: NetworkName.BnbTestnet,
    label: "tBNB",
    hover: "Binance Testnet",
    currency: "BNB",
    icon: BNBIcon,
  },
  POLYGON: {
    networkName: NetworkName.Polygon,
    label: "MATIC",
    hover: "Polygon",
    currency: "MATIC",
    icon: PolyIcon,
  },
  POLYGON_TESTNET: {
    networkName: NetworkName.PolygonTestnet,
    label: "tMATIC",
    hover: "Polygon Testnet",
    currency: "MATIC",
    icon: PolyIcon,
  },
  KUJI: {
    networkName: NetworkName.Kuji,
    label: "KUJI",
    hover: "Kujira",
    currency: "KUJI",
    icon: KujiIcon,
  },
  KUJI_TESTNET: {
    networkName: NetworkName.KujiTestnet,
    label: "tKUJI",
    hover: "Kujira Testnet",
    currency: "KUJI",
    icon: KujiIcon,
  },
  ARBITRUM: {
    networkName: NetworkName.Arbitrum,
    label: "ARB",
    hover: "Arbitrum",
    currency: "ARB",
    icon: ArbIcon,
  },
  ARBITRUM_TESTNET: {
    networkName: NetworkName.ArbitrumTestnet,
    label: "tARB",
    hover: "Arbitrum Testnet",
    currency: "ARB",
    icon: ArbIcon,
  },
  DEVNET_L1A: {
    networkName: devNetworkName.DevnetL1A,
    label: "devnetA",
    hover: "Devnet A",
    currency: "Cweb",
    icon: CwebIcon,
  },
  DEVNET_L1B: {
    networkName: devNetworkName.DevnetL1B,
    label: "devnetB",
    hover: "Devnet B",
    currency: "Cweb",
    icon: CwebIcon,
  },
};

//Icon handlind for select s *** dropdown icon *** in mobile screens
export const SelectIcon = createSvgIcon(
  <path
    d="M16.631 0 9 6.367 1.369 0 0 1.491 9 9l9-7.509z"
    fill="#D3D3EF"
    fillRule="evenodd"
  />,
  "Select"
);
/**  MUI Connect Metamask Button Styling  **/
export const MetamaskButton = styled(Button)<ButtonProps>(() => ({
  borderadius: "6px!important",
  backgroundImage: "linear-gradient(to bottom, #aeaed1, #626089) !important",
  fontSize: "12px!important",
  boxShadow: "none !important",
  height: "44px!important",
  width: "100% !important",
  fontFamily: ' "DINosaur Med", "CheyenneMed", sans-serif!important',
  fontWeight: 700,
  fontStretch: "normal!important",
  fontStyle: "normal!important",
  lineHeight: "0.86!important",
  letterSpacing: "1.17px!important",
  color: "#fff!important",
  maxWidth: "175px !important",
}));
/**MUI Table Container Search Paper Styling**/
export const SearchPaper = styled(Paper)<PaperProps>(() => ({
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  minWidth: 175,
  backgroundColor: "transparent",
  border: "solid 1px #4f4f72",
  height: "40px",
  boxShadow: "none",
}));

/**MUI Table Container Search InputBase Styling**/
export const SearchInputBase = styled(InputBase)<InputBaseProps>(() => ({
  ml: 1,
  flex: 1,
  fontSize: "14px",
  color: "#d3d3ef!important",
  fontFamily: '"DINosaur Med", "CheyenneMed"',
  fontWeight: 500,
  textAlign: "right",
  minWidth: "174px",
  height: "100%",
  textOverflow: "ellipsis",
  marginRight: "10px",
  /**MUI Table Container Search InputBase inner input Styling for development**/
  ".MuiInputBase-input.css-yz9k0d-MuiInputBase-input": {
    color: "#d3d3ef !important",
    textIndent: "10px!important",
    /**MUI Table Container Search InputBase inner input placeholder Styling for development  **/
    "&::-webkit-input-placeholder": {
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for development  **/
    "&::-moz-placeholder": {
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for development  **/
    "&::-ms-input-placeholder": {
      opacity: "1!important",
    },
  },
  /**MUI Table Container Search InputBase inner input Styling for build**/
  ".MuiInputBase-input.css-mnn31": {
    textIndent: "10px!important",
    /**MUI Table Container Search InputBase inner input placeholder Styling for build**/
    "&::-webkit-input-placeholder": {
      color: "#d3d3ef!important",
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for build**/
    "&::-moz-placeholder": {
      color: "#d3d3ef!important",
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for build**/
    "&::-ms-input-placeholder": {
      color: "#d3d3ef!important",
      opacity: "1!important",
    },
  },
}));

export const TabFormControl = styled(FormControl)<FormControlProps>(() => ({
  m: 1,
  margin: "0px !important",
  width: "100% !important",
  minWidth: "120px",
  height: "44px !important",
  background: "#37385f",
  borderRadius: "6px",
  ".MuiOutlinedInput-root": {
    height: "44px !important",
  },
  /** MUI Table Container Form Control Select icon Styling for development **/
  ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
    top: "18px !important",
  },
  /** MUI Table Container Form Control Select icon Styling for development **/
  ".css-1636szt": {
    top: "18px !important",
  },
}));

/**  MUI Table Container Menu Divider Styling for mobile screen **/
export const TabMenuDivider = styled(Divider)<DividerProps>(() => ({
  borderStyle: "solid",
  borderColor: "#4f4f72 !important",
  borderBottomWidth: "thin",
  marginLeft: "5px !important",
  marginRight: "5px !important",
}));

/**  MUI Table Container Divider Styling for mobile screen  **/
export const ContainerDivider = styled(Divider)<DividerProps>(() => ({
  height: "3px!important",
  backgroundColor: "#4f4f72 !important",
  margin: "20px 0px 22px 0px !important",
}));
