export default function findRawDataElement(rawDataState: any, rawData: any) {
  if (rawData?.children) {
    const mathchingRes = rawDataState?.rawData
      .filter(
        (data: any) =>
          JSON.stringify(data?.childTxs[0]?.direct_children) ===
          JSON.stringify(rawData.children)
      )
      .filter((item: any) => item !== undefined); // Filter out null values from the result
    return mathchingRes;
  }
}
