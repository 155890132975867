// The purpose of this config system is to enable runtime environment variables
// in a production build of react.
//
// We do this by running envsub (like envsubst, the Unix command) on index.html
// to substitute environment variables into the main html file.
//
// We then pick those variables up here.

import _ from "lodash";
import { BlockChains } from "./components/TableContainer/CustomizedMui";
import { NetworkName } from "./generated/graphql";
import { NetworkName as devNetworkName } from "./generated/graphql-devnet";

const RE = /\$\{\s*\w+\s*\}/;

const isNotInterpolated = (kv: (string | null)[]): kv is [string, string] =>
  !!kv[0] && !!kv[1] && !RE.test(kv[1]);

export const read = (): Record<string, string> =>
  Array.from(document.querySelectorAll('[itemid="config"] meta[itemprop]'))
    // map to k,v array
    .map((el) => [el.getAttribute("itemprop"), el.getAttribute("content")])
    // remove non-interpolated entries
    .filter(isNotInterpolated)
    // convert to object
    .reduce(
      (m, [k, v]) => ({
        ...m,
        [k]: v,
      }),
      {}
    );

const getSubscriptionEndpoint = (): string | undefined => {
  const { REACT_APP_API_ENDPOINT, REACT_APP_SUBSCRIPTION_API_ENDPOINT } =
    process.env;
  if (REACT_APP_SUBSCRIPTION_API_ENDPOINT) {
    return REACT_APP_SUBSCRIPTION_API_ENDPOINT;
  }
  if (REACT_APP_API_ENDPOINT) {
    return REACT_APP_API_ENDPOINT.replace(/^http/i, "ws");
  }
};

export function* getEnabledNetworks(tickers: string): Generator<NetworkName> {
  const tickerToName = _.fromPairs(
    Object.values(BlockChains).map((bc) => [bc.label, bc.networkName])
  );

  for (const network of tickers.split(",")) {
    const name = tickerToName[network];
    if (name) {
      yield name as NetworkName;
    } else {
      console.warn("Unknown Network Ticker: " + network);
    }
  }
}

function* getEnabledDevNetworks(tickers: string): Generator<devNetworkName> {
  const tickerToName = _.fromPairs(
    Object.values(BlockChains).map((bc) => {
      return [bc.label, bc.networkName];
    })
  );

  for (const network of tickers.split(",")) {
    const name = tickerToName[network];
    if (name) {
      yield name;
    } else {
      console.warn("Unknown Network Ticker: " + network);
    }
  }
}
const {
  REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT,
  REACT_APP_SUBSCRIPTION_API_ENDPOINT = getSubscriptionEndpoint(),
  REACT_APP_WALLET_API_ENDPOINT = process.env.REACT_APP_WALLET_API_ENDPOINT ??
    `${process.env.REACT_APP_API_ENDPOINT}wallet`,
  REACT_APP_WALLET_SUBSCRIPTION_API_ENDPOINT = process.env
    .REACT_APP_WALLET_SUBSCRIPTION_API_ENDPOINT ??
    `${getSubscriptionEndpoint()}wallet`,
  REACT_APP_ENABLED_NETWORKS: tickers = process.env
    .REACT_APP_ENABLED_NETWORKS ??
    "BTC,BCH,ETH,LTC,EGLD,BNB,Polygon,KUJI,DevnetL1A,DevnetL1B",
  TOTAL_L1_TX = process.env.TOTAL_L1_TX ?? "0",
  TOTAL_CWEB_TX = process.env.TOTAL_CWEB_TX ?? "0",
  TOTAL_TOKENS = process.env.TOTAL_TOKENS ?? "0",
  REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT = process.env
    .REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT,
  REACT_APP_LIVENESS_ENDPOINT = process.env.REACT_APP_LIVENESS_ENDPOINT,
  REACT_APP_DEVNET = process.env.REACT_APP_DEVNET,
  REACT_APP_COINWEB_STABLE_TOKEN_ISSUED = process.env
    .REACT_APP_COINWEB_STABLE_TOKEN_ISSUED,
} = read();

const REACT_APP_ENABLED_NETWORKS =
  REACT_APP_DEVNET === "true"
    ? [...getEnabledDevNetworks(tickers)]
    : [...getEnabledNetworks(tickers)];

export {
  REACT_APP_API_ENDPOINT,
  REACT_APP_SUBSCRIPTION_API_ENDPOINT,
  REACT_APP_WALLET_API_ENDPOINT,
  REACT_APP_WALLET_SUBSCRIPTION_API_ENDPOINT,
  REACT_APP_ENABLED_NETWORKS,
  TOTAL_L1_TX,
  TOTAL_CWEB_TX,
  TOTAL_TOKENS,
  REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT,
  REACT_APP_LIVENESS_ENDPOINT,
  REACT_APP_DEVNET,
  REACT_APP_COINWEB_STABLE_TOKEN_ISSUED,
};
