import "./L2TxLogCard.css";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router-dom";
import {
  TxLoadedGql,
  ContractGql,
  ParentGql,
  EmbeddedGql,
} from "../../generated/graphql";
import ContactParentSection from "./ContactParentSection";
import EmbeddedParentSection from "./EmbeddedParentSection";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";

const MOBILE_WITDTH = 450;

type TxLoadedSectionProps = {
  data: TxLoadedGql;
  width: any;
  networkName: string;
};

const TxLoadedSection = (props: TxLoadedSectionProps) => {
  const { data, width } = props;
  const [, setSearchParams] = useSearchParams();

  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WITDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WITDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div
        className="relativeCon "
        onClick={() => {
          if (
            data.parents &&
            Array.isArray((data.parents as ContractGql).parents) &&
            (data.parents as ContractGql).parents[0] &&
            ((data.parents as ContractGql).parents as ParentGql[])[0].l2Txid
          ) {
            setSearchParams({
              hash: ((data.parents as ContractGql).parents as ParentGql[])[0]
                .l2Txid,
            });
          } else if (
            data.parents &&
            (data.parents as EmbeddedGql).l1Txs &&
            (data.parents as EmbeddedGql).l1Txs[0]
          ) {
            setSearchParams({
              hash: (data.parents as EmbeddedGql).l1Txs[0],
            });
          }
        }}
      >
        <div className="absoluteLeftText ParentsCarousel-icon">
          <img className="linkArrow" src={ActiveSwipe} alt="swipe"></img>

          <Typography
            className="TxLogFontBold14 linkHover"
            sx={{ top: "-15px" }}
          >
            Parents
          </Typography>
        </div>
      </div>

      {data.parents.__typename === "EmbeddedGql" && (
        <EmbeddedParentSection data={data.parents} width={width} />
      )}
      {data.parents.__typename === "ContractGql" && (
        <ContactParentSection data={data.parents} width={width} />
      )}
    </div>
  );
};
export default memo(TxLoadedSection);
