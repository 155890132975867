export enum BlockchainExplorer {
  BITCOIN = "https://explorer.btc.com/btc/transaction/",
  BITCOIN_TESTNET = "https://blockstream.info/testnet/tx/",
  BITCOIN_CASH = "https://explorer.btc.com/bch/transaction/",
  BITCOIN_CASH_TESTNET = "https://tbch.loping.net/tx/",
  ETHEREUM = "https://etherscan.io/tx/",
  ETHEREUM_TESTNET = "https://goerli.etherscan.io/tx/",
  LITECOIN = "https://litecoinblockexplorer.net/tx/",
  LITECOIN_TESTNET = "https://tltc.bitaps.com/",
  ELROND = "https://egldscan.com/transactions/",
  ELROND_TESTNET = "https://testnet-explorer.multiversx.com/transactions/",
  BNB = "https://bscscan.com/tx/",
  BNB_TESTNET = "https://testnet.bscscan.com/tx/",
  POLYGON = "https://polygonscan.com/tx/",
  POLYGON_TESTNET = "https://mumbai.polygonscan.com/tx/",
  KUJI = "https://finder.kujira.network/kaiyo-1/tx/",
  KUJI_TESTNET = "https://finder.kujira.network/harpoon-4/tx/",
  DOGECOIN = "https://dogechain.info/tx/",
  DOGECOIN_TESTNET = "https://explorer-testnet.dogechain.dog/tx/",
  ARBITRUM = "https://arbiscan.io/tx/",
  ARBITRUM_TESTNET = "https://sepolia.arbiscan.io/tx/",
  DEVNET_L1A = "https://explorer-devnet.coinweb.io/?hash=",
  DEVNET_L1B = "https://explorer-devnet.coinweb.io/?hash=",
  COINWEB = "https://explorer.coinweb.io/?hash=",
  COINWEB_DEVNET = "https://explorer-devnet.coinweb.io/?hash=",
}
