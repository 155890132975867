import {
  NetworkName,
  BlockFragment,
  FullTxLogFragment,
} from "../../generated/graphql-devnet";
import React from "react";
import DevSearchQueryContainer from "../devSearchQuery";
import LoadingBox from "../LoadingBox";
import TableData from "../TableData";
import BlockError from "../../assets/Icons/block-error.svg";
import { REACT_APP_ENABLED_NETWORKS } from "../../conf";
import DevSearchL2TxLogQueryContainer from "../devSearchQuery/L2TxidLog";
import L2TxLogCard from "../L2TxLogCard/L2TxLogCard";

type Props = {
  setNetwork: (n: NetworkName | undefined) => void;
  hash: string;
  width: number;
  onSetSubscription: (value: boolean) => void;
};
export const DataNotFoundSection = (
  <div id="Error-container">
    <div id="Error-icon-container">
      <img src={BlockError} alt="not_found"></img>
    </div>
    <div id="Error-message-container">
      <div className="Error-text">DATA NOT FOUND</div>
    </div>
  </div>
);
const NetworkSelector: React.FC<Props> = ({
  setNetwork,
  hash,
  width,
  onSetSubscription,
}) => {
  let data: BlockFragment | undefined;
  let logData: FullTxLogFragment[] | undefined;
  let block_loading = false;

  for (const network of REACT_APP_ENABLED_NETWORKS) {
    const queryResult = DevSearchQueryContainer({ hash, network });
    if (queryResult !== "loading" && queryResult !== "error" && queryResult) {
      data = queryResult;
      setNetwork(network);
    } else if (queryResult === "loading") block_loading = true;
  }

  let tx_loading = false;
  let txid = hash;
  for (const network of REACT_APP_ENABLED_NETWORKS) {
    const queryResult = DevSearchL2TxLogQueryContainer({ txid, network });
    if (queryResult !== "loading" && queryResult !== "error" && queryResult) {
      if (queryResult.length > 0) {
        logData =
          logData === undefined ? queryResult : logData.concat(queryResult);
        setNetwork(network);
      }
    } else if (queryResult === "loading") tx_loading = true;
  }

  if (block_loading || tx_loading) {
    /* * ** ** * Loading Display Section * * ** ** */
    // TODO: should LoadingBox have width={...} set?
    return <LoadingBox>Searching...</LoadingBox>;
  }

  return (
    <div>
      {data !== undefined && (
        <TableData
          width={width}
          data={[data]}
          onSetSubscription={onSetSubscription}
        />
      )}
      {Array.isArray(logData) && logData.length > 0 && (
        <div>
          {logData.map((log) => {
            return (
              <L2TxLogCard
                width={width}
                data={log}
                setNetwork={setNetwork}
              />
            );
          })}
        </div>
      )}
      {data === undefined &&
        (!Array.isArray(logData) || logData.length === 0) && {
          DataNotFoundSection,
        }}
    </div>
  );
};

export default NetworkSelector;
