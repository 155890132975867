import "./L2TxLogCard.css";
import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CopyIcon from "components/CopyIcon";
import { handleTxIds } from "./L2TxLogCard";
import { ContractGql } from "../../generated/graphql";
import { Fade, Tooltip } from "@mui/material";

const MOBILE_WITDTH = 450;

type ContactParentSectionProps = {
  data: ContractGql;
  width: any;
};

const ContactParentSection = (props: ContactParentSectionProps) => {
  const { data, width } = props;

  const [, setSearchParams] = useSearchParams();

  function RenderTxIdTypography(prop: { txid: string }) {
    return (
      <div className="L2TxId-row">
        <Typography className="BoldFont14 L2TxId-container" align="center">
          {prop.txid ? (
            <>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#4f4f72 !important",
                      padding: "0px !important",
                      marginTop: "-1px !important",
                    },
                  },
                }}
                disableFocusListener
                TransitionComponent={Fade}
                title={<div id="TxId-tooltip">{prop.txid}</div>}
              >
                <div
                  className="L2TxId-container"
                  onClick={() => {
                    setSearchParams({ hash: prop.txid });
                  }}
                  id="TxId-container"
                >
                  {handleTxIds(prop.txid)}
                </div>
              </Tooltip>
            </>
          ) : (
            "N/A"
          )}
        </Typography>
        <CopyIcon data={prop.txid} />
      </div>
    );
  }

  return (
    <div
      className="L2TxLogCard-data-table "
      style={{
        marginBottom: width > MOBILE_WITDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WITDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div>
        <Typography className="BoldFont16">Contract Name</Typography>
        <Typography
          className="BoldFont14"
          style={{ overflowWrap: "anywhere" }}
          gutterBottom
        >
          {data.contract}
        </Typography>
      </div>

      <div className="ParentsCenter-container">
        <div>
          <Typography className="BoldFont16">Created by L2 Txids</Typography>
          <RenderTxIdTypography txid={data.parents[0].l2Txid} />
        </div>
      </div>
    </div>
  );
};
export default memo(ContactParentSection);
