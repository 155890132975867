import "./TableContainer.css";
import * as React from "react";
import { Tooltip, MenuItem, Select, Fade, IconButton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import trim0xFromHash from "utils/trim0xFromHash";
import NetworkSelector from "./NetworkSelector";
import SearchIcon from "assets/Icons/icon-search-default.svg";
import SearchIconBlue from "assets/Icons/icon-search-default_blue.svg";
import AllIcon from "assets/Icons/icon-blockchains-default.svg";
import Check from "assets/Icons/check.svg";
import { BlockFragment, NetworkName } from "generated/graphql";
import TableData from "../TableData";
import Counter from "../Counter/Counter";
import { UseNetworkState } from "../../useNetworkState";
import LoadingBox from "../LoadingBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  TabFormControl,
  SelectIcon,
  MetamaskButton,
  SearchPaper,
  SearchInputBase,
  TabMenuDivider,
  ContainerDivider,
  BlockChains,
  BlockChain,
} from "./CustomizedMui";
import {
  REACT_APP_ENABLED_NETWORKS,
  REACT_APP_COINWEB_STABLE_TOKEN_ISSUED,
} from "conf";
// import { Web3ReactProvider } from '@web3-react/core';
const theme = createTheme({
  components: {
    /**  MUI SelectMenu Paper Styling for mobile screen **/
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#37385f !important",
          color: "#d3d3ef !important",
          right: "20px!important",
          left: "20px!important",
          width: "calc(100% - 20px) !important",
          top: "49px !important",
          boxShadow: "none !important",
          height: "auto",
          bottom: "48px !important",
          borderRadius: "6px !important",
        },
      },
    },
    /**  MUI SelectMenu Paper Styling for mobile screen **/
    MuiMenu: {
      styleOverrides: {
        root: {
          backgroundColor: "#1f1d47 !important",
        },
      },
    },
    /**  MUI Select Menu u-List Styling for mobile screen **/
    MuiList: {
      styleOverrides: {
        root: {
          marginTop: "4px !important",
        },
      },
    },
    /**  MUI Select MenuItem Styling for mobile screen **/
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
          height: "64px !important",
        },
      },
    },
  },
});

type loading = "loading";

type TableContainerProps = {
  cwTxVol: number;
  cwTxs: number;
  l1Txs: number;
  networkFetchSizes: number[];
  setNetworkFetchSizes: (sizes: number[]) => void;
  width: number;
  data: BlockFragment[];
  tabs: (UseNetworkState | undefined | loading)[];
  networkNums: number;
  setRowsize: (sizes: number) => void;
  rowsize: number;
  availableTabs: boolean[];
  activeMetamask: boolean;
  onSetSubscription: (value: boolean) => void;
};

// Function to handle Coinweb Stable Tokens Issued
const formatTokenAmount = (token: number): string => {
  const tokenStr = String(token);
  if (tokenStr.length > 6) {
    const tokenStr_1 = tokenStr.slice(0, tokenStr.length - 6);
    const tokenStr_2 = tokenStr.slice(-6, -5);
    return tokenStr_2 !== "0"
      ? "$ " + tokenStr_1 + "." + tokenStr_2 + " M"
      : "$ " + tokenStr_1 + " M";
  } else if (tokenStr.length === 6) {
    return "$ 0." + tokenStr.slice(-6, -5) + " M";
  } else return "$ " + token.toLocaleString();
};

// NB, the code below works with indexes instead of block chain names.
// Moreover it requires order of BlockChainArray be exactly the same
// as order of REACT_APP_ENABLED_NETWORKS.
const BlockChainArray: BlockChain[] = REACT_APP_ENABLED_NETWORKS.map(
  (n) => BlockChains[n]
);

const TableContainer = (props: TableContainerProps) => {
  const {
    cwTxVol,
    cwTxs,
    l1Txs,
    networkFetchSizes,
    setNetworkFetchSizes,
    width,
    data,
    tabs,
    networkNums,
    setRowsize,
    rowsize,
    availableTabs,
    activeMetamask,
    onSetSubscription,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  //state index for tab selection, deafult 0 (ALL)
  const [index, setIndex] = React.useState<undefined | number>(0);
  //state if search function is active or not
  const [search, setSearch] = React.useState<any>(false);
  //final search block hash
  const [searchExactQuery, setSearchExactQuery] = React.useState("");
  //state to selection menu display for mobile
  const [selectDisplay, setSelectDisplay] = React.useState(false);
  //Search input text state
  const [searchQuery, setSearchQuery] = React.useState("");
  //Search input width state
  const [searchWidth, setWidth] = React.useState("174");
  //Search icon state for color change on hover and active search
  const [searchIconState, setSearchIconState] = React.useState(false);
  //Metamask Account state to store
  const [metamaskAccount, setMetamaskAccount] = React.useState(null);

  const tabData = index && tabs[index - 1] ? tabs[index - 1] : undefined;
  const enableMetamask = process.env.REACT_APP_METAMASK_ENABLED === "true";

  const displayCoinwebStableTokenIssued =
    REACT_APP_COINWEB_STABLE_TOKEN_ISSUED === "true";
  React.useEffect(() => {
    const hash = searchParams.get("hash");
    if (hash === "") {
      setSearch(false);
      setSearchQuery("");
    }
    if (hash) {
      setSearchExactQuery(trim0xFromHash(hash.trim()));
      setSearch(true);
      setSearchIconState(true);
      let newWidth = String(hash.length * 7 + "px ");
      setWidth(newWidth);
      setSearchQuery(hash);
    }
  }, [setSearchExactQuery, searchParams]);

  const handleToggle = () => {
    const blockHash: string = trim0xFromHash(searchQuery);
    if (blockHash !== "") {
      setSearchExactQuery(blockHash.trim());
      setSearch(true);
      setSearchIconState(true);
      setSearchParams({ hash: blockHash });
    } else {
      searchParams.delete("hash");
      setSearchParams(searchParams);
      setSearch(false);
      setIndex(0);
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let newWidth = String(e.target.value.length * 7 + "px ");
    setWidth(newWidth);
    setSearchQuery(e.target.value);
    setSearchIconState(true);
  };

  const setSearchTrue = () => {
    setSearchIconState(true);
  };

  const setSearchFalse = () => {
    if (!search) setSearchIconState(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleToggle();
    }
  };
  // CallBack Function to set rowsize from other tabs
  const handleSize = React.useCallback(() => {
    if (index === 0) {
      const newSize = rowsize + 10;
      setRowsize(newSize);
    } else {
      let sizes = [...networkFetchSizes]; // create the copy of state array
      if (index && sizes[index - 1] + 10 <= 40)
        sizes[index - 1] = sizes[index - 1] + 10; //new value
      setNetworkFetchSizes(sizes);
    }
  }, [setNetworkFetchSizes, index, networkFetchSizes, rowsize, setRowsize]);

  // CallBack Function to highlight found block network after search action
  const handleSearchNetwork = React.useCallback(
    (network: NetworkName | undefined) => {
      if (!network) setIndex(undefined);
      else {
        for (let i = 0; i < REACT_APP_ENABLED_NETWORKS.length; i++) {
          if (REACT_APP_ENABLED_NETWORKS[i] === network) setIndex(i + 1);
        }
      }
    },
    [setIndex]
  );

  const handleClick = React.useCallback(
    (_event: any, index: number) => {
      setIndex(index);
      setSearch(false);
      setRowsize(10);
      let sizes = new Array(REACT_APP_ENABLED_NETWORKS.length).fill(10);
      setNetworkFetchSizes(sizes);
    },
    [setIndex, setSearch, setNetworkFetchSizes, setRowsize]
  );

  // Function to handle Blockchain Network Tabs onClick
  const handleTabClick = React.useCallback(
    (event: any, index: number) => {
      setIndex(index);
      setSearch(false);
      setSearchIconState(false);
      setRowsize(10);
      setSearchQuery("");
      let sizes = new Array(REACT_APP_ENABLED_NETWORKS.length).fill(10);
      setNetworkFetchSizes(sizes);
      setWidth("174");
      searchParams.delete("hash");
      setSearchParams(searchParams);
    },
    [
      setIndex,
      searchParams,
      setSearchParams,
      setSearch,
      setSearchIconState,
      setNetworkFetchSizes,
      setRowsize,
    ]
  );

  async function loginWithMetaMask() {
    if (!metamaskAccount) {
      const accounts = await (window as any).ethereum
        .request({ method: "eth_requestAccounts" })
        .catch((e: any) => {
          console.error(e.message);
          return;
        });
      if (!accounts) {
        return;
      }

      (window as any).userWalletAddress = accounts[0];
      // userWallet.innerText = (window as any).userWalletAddress
      setMetamaskAccount(accounts[0]);
    } else {
      return;
    }
  }

  const Statistics = () => (
    <>
      <Counter
        label={"Coinweb Transactions"}
        statistics={cwTxs.toLocaleString()}
      />
      <Counter
        label={"Layer 1 Transactions"}
        statistics={l1Txs.toLocaleString()}
      />
      {displayCoinwebStableTokenIssued && (
        <Counter
          label={"Coinweb Stable Tokens Issued"}
          statistics={formatTokenAmount(cwTxVol)}
        />
      )}

      <Counter label={"Available Networks"} statistics={String(networkNums)} />
    </>
  );
  return (
    <div
      id={
        width < 1050 ? "Table-outer-container-mobile" : "Table-outer-container"
      }
    >
      <div id="Table-container">
        {width > 600 ? (
          // Search input and Tabs section for Desktop Screens
          <div id="Upper-data-table">
            <div id="Counter-outer-container">
              <Statistics />
            </div>
            {activeMetamask && enableMetamask ? (
              <div id="Metamask-container">
                <MetamaskButton
                  type="button"
                  // id="Button-mobile"
                  onClick={() => {
                    loginWithMetaMask();
                  }}
                  variant="contained"
                >
                  {metamaskAccount ? "CONNECTED" : "CONNECT METAMASK"}
                </MetamaskButton>
                {metamaskAccount ? (
                  <div id="AccountId_container">
                    <p>Account Id: {metamaskAccount}</p>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div id="Search-container">
              <div id="Search">
                <SearchPaper
                  key="Search-Paper"
                  onMouseEnter={() => setSearchIconState(true)}
                  onMouseLeave={() =>
                    !search ? setSearchIconState(false) : null
                  }
                  onKeyDown={handleKeyDown}
                >
                  <IconButton
                    onClick={handleToggle}
                    sx={{
                      padding: "10px 2px 10px 16px",
                      color: "#d3d3ef !important",
                    }}
                    aria-label="search"
                  >
                    <img
                      inputMode="search"
                      src={searchIconState ? SearchIconBlue : SearchIcon}
                      alt="Search"
                    ></img>
                  </IconButton>

                  <SearchInputBase
                    sx={{ width: searchWidth }}
                    value={searchQuery}
                    id="Search-InputBase"
                    placeholder="Search by Block/L2Tx Hash"
                    inputProps={{ "aria-label": "block hash" }}
                    key="search-inputBase"
                    onChange={(e) => handleChange(e)}
                  />
                </SearchPaper>
              </div>
            </div>
            {index !== undefined && (
              <div id="Tab-container">
                <div id="Tabs-container">
                  <div
                    className={`Tab ${index === 0 ? "Active" : ""}`}
                    onClick={(e) => handleClick(e, 0)}
                  >
                    <div
                      className="Tab-icon-container"
                      style={{ height: width < 900 ? "50px" : "55px" }}
                    >
                      <img className="Tab-icon" src={AllIcon} alt="All"></img>
                    </div>
                    <div>All Chains</div>
                    <div
                      className="Tab-bottom-line"
                      style={{
                        backgroundColor: index === 0 ? "#00c3ff" : "#4f4f72",
                      }}
                    ></div>
                  </div>
                  {BlockChainArray.map((tab, i) => {
                    return availableTabs[i] ? (
                      <div
                        key={tab.label}
                        className={`Tab ${index === i + 1 ? "Active" : ""}`}
                        onClick={(e) => handleTabClick(e, i + 1)}
                      >
                        <div>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#4f4f72 !important",
                                  padding: "0px !important",
                                  marginTop: "-1px !important",
                                },
                              },
                            }}
                            disableFocusListener
                            TransitionComponent={Fade}
                            title={
                              <div className="Tab-tooltip">{tab.hover}</div>
                            }
                          >
                            <div className="Tab-icon-container">
                              <img
                                className="Tab-icon"
                                src={tab.icon}
                                alt={tab.hover}
                              ></img>
                            </div>
                          </Tooltip>
                          <div>{tab.label}</div>
                          <div
                            className="Tab-bottom-line"
                            style={{
                              backgroundColor:
                                index === i + 1 ? "#00c3ff" : "#4f4f72",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          // Search input and Tabs section for Mobile Screens
          <div id="Upper-mobile-data-table">
            <div id="Counter-outer-container-mobile">
              <Statistics />
            </div>

            <div className="Tab-icon-container" style={{ height: "56px" }}>
              <img
                className="Tab-icon"
                src={!index ? AllIcon : BlockChainArray[index - 1].icon}
                alt="All"
                style={{ width: "42px" }}
              ></img>
            </div>
            <div id="Search-container">
              <div id="Search-mobile">
                <SearchPaper
                  onMouseEnter={setSearchTrue}
                  onMouseLeave={setSearchFalse}
                  onKeyDown={handleKeyDown}
                >
                  <IconButton
                    onClick={handleToggle}
                    sx={{
                      padding: "10px 2px 10px 16px",
                      color: "#d3d3ef !important",
                    }}
                    aria-label="search"
                  >
                    <img
                      inputMode="search"
                      src={searchIconState ? SearchIconBlue : SearchIcon}
                      alt="Search"
                    ></img>
                  </IconButton>

                  <SearchInputBase
                    sx={{
                      width: searchWidth,
                    }}
                    id="Search-InputBase"
                    placeholder="Search by Block/L2Tx Hash"
                    inputProps={{ "aria-label": "block hash" }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </SearchPaper>
              </div>
            </div>
            {index && (
              <div>
                <TabFormControl>
                  <ThemeProvider theme={theme}>
                    <Select
                      open={selectDisplay}
                      value={index}
                      onChange={(e) =>
                        handleTabClick(e, e.target.value as unknown as number)
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue={0}
                      IconComponent={SelectIcon}
                      onOpen={() => setSelectDisplay(true)}
                      onClose={() => setSelectDisplay(false)}
                    >
                      <MenuItem key="All Chain" value={0}>
                        {selectDisplay ? (
                          <div className="Select-item-wrapper">
                            <div className="Select-item-container">
                              <div className="Select-icon-container">
                                <img
                                  style={{ width: "29px" }}
                                  src={AllIcon}
                                  alt="All"
                                ></img>
                              </div>
                              <div
                                className="Select-value"
                                style={{
                                  color: index === 0 ? "#ffffff" : undefined,
                                }}
                              >
                                All Chains
                              </div>
                              {index === 0 ? (
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    alignSelf: "center",
                                  }}
                                >
                                  <div className="Select-icon-selected">
                                    <img
                                      style={{ width: "17px" }}
                                      src={Check}
                                      alt="check"
                                    ></img>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div id="Select-divider">
                              <TabMenuDivider variant="middle" />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="Select-value"
                            style={{ color: "#fff" }}
                          >
                            All Chains
                          </div>
                        )}
                      </MenuItem>

                      {BlockChainArray.map((tab, index) => {
                        return tabs[index] ? (
                          <MenuItem key={tab.label} value={index + 1}>
                            {selectDisplay ? (
                              <div className="Select-item-wrapper">
                                <div className="Select-item-container">
                                  <div className="Select-icon-container">
                                    <img
                                      width={29}
                                      src={tab.icon}
                                      alt={tab.hover}
                                    ></img>
                                  </div>
                                  <div
                                    className="Select-value"
                                    style={{
                                      color:
                                        index === index + 1
                                          ? "#ffffff"
                                          : undefined,
                                    }}
                                  >
                                    {tab.hover} - {tab.label}
                                  </div>
                                  {index === index + 1 ? (
                                    <div
                                      style={{
                                        marginLeft: "auto",
                                        alignSelf: "center",
                                      }}
                                    >
                                      <div className="Select-icon-selected">
                                        <img
                                          style={{ width: "17px" }}
                                          src={Check}
                                          alt="check"
                                        ></img>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {index === networkNums - 1 ? null : (
                                  <div id="Select-divider">
                                    <TabMenuDivider variant="middle" />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                key={tab.label}
                                className="Select-value"
                                style={{ color: "#fff" }}
                              >
                                {tab.hover} - {tab.label}
                              </div>
                            )}
                          </MenuItem>
                        ) : null;
                      })}
                    </Select>
                  </ThemeProvider>
                </TabFormControl>
              </div>
            )}
            <ContainerDivider variant="middle" />
          </div>
        )}
        {search === true ? (
          <div id="Block-data-table">
            <NetworkSelector
              setNetwork={handleSearchNetwork}
              hash={searchExactQuery}
              width={width}
              onSetSubscription={onSetSubscription}
            />
          </div>
        ) : tabData === "loading" ? (
          <LoadingBox>Loading... </LoadingBox>
        ) : (
          <div id="Block-data-table">
            <TableData
              data={!tabData ? data : tabData.lastBlocks}
              width={width}
              onSetSubscription={onSetSubscription}
            />
            {data.length > 1 && index !== 0 ? (
              <div id="Loadmore-container">
                <button
                  type="button"
                  id="Loadmore-button"
                  onClick={() => handleSize()}
                >
                  Load more
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(TableContainer);
