import * as React from "react";
import { BlockFragment } from "../../generated/graphql";
import { BlockFragment as devBlockFargment } from "../../generated/graphql-devnet";
import {
  Collapse,
  TableCell,
  IconButton,
  Tooltip,
  Fade,
  TableRow,
} from "@mui/material";
import { useInterval } from "hooks/useInterval";
import calculateTime from "utils/calculateTime";
import formatTime from "utils/formatTime";
import CardContainer from "../CardContainer/CardContainer";
import "./styles.css";
import CopyIcon from "components/CopyIcon";
import DownIcon from "../../assets/Icons/down.svg";
import DownBlueIcon from "../../assets/Icons/down_blue.svg";
import UpIcon from "../../assets/Icons/up.svg";
import "./styles.css";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { TableRowProps } from "@mui/material/TableRow";
import { CollapseProps } from "@mui/material/Collapse";
import { BlockChains } from "../TableContainer/CustomizedMui";

/**  MUI Data Table Row Styling  **/
const DataTableRow = styled(TableRow)<TableRowProps>(() => ({
  backgroundColor: "#20214d !important",
  ":hover": {
    backgroundColor: "#282a54!important",
  },
}));

/**  MUI Data Table Row Styling for Collapse section **/
const DataTableCollapseRow = styled(TableRow)<TableRowProps>(() => ({
  backgroundColor: "#20214d !important",
  borderBottom: "1px solid #4f4f72 !important",
  animation: `$myEffect  1.5s ease-in`,
  /**  MUI Data Table Row Styling for Collapse section for development **/
  "td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root":
    {
      borderBottom: "0px solid #4f4f72 !important",
      padding: "0px !important",
    },
  /**  MUI Data Table Row Styling for Collapse section for build **/
  "td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-q34dxg": {
    borderBottom: "0px solid #4f4f72 !important",
    padding: "0px !important",
  },
  "@keyframes myEffect": {
    from: {
      borderBottom: "10px solid #fff !important",
    },
    to: {
      borderBottom: "1px solid #4f4f72 !important",
    },
  },
}));

/**  MUI Data Table Cell Styling  **/
const DataTableCell = styled(TableCell)<TableCellProps>(() => ({
  borderBottom: "0px solid #4f4f72 !important",
  fontFamily: '"DINosaur Med","CheyenneMed",  sans-serif!important',
  fontSize: "14px",
  fonteWeight: "500 !important",
  fontStretch: "normal !important",
  fontStyle: "normal !important",
  lineHeight: "1.5!important",
  letterSpacing: "normal !important",
  color: "#d3d3ef !important",
  padding: "12px !important",
  height: "39px !important;",
}));

/**  MUI Data Table Collapse Styling  **/
const DataCollapse = styled(Collapse)<CollapseProps>(() => ({
  borderBottom: "0px solid #4f4f72 !important",
}));
type RowProps = {
  setOpenHash: (x: string | undefined) => void;
  openHash: string | undefined;
  row: BlockFragment | devBlockFargment;
  width: number;
  index: number;
  rowHash: string;
  onSetSubscription: (value: boolean) => void;
};

//***************TABLE DATA ROW FUNCTION COMPONENT********//
/////********************************************************/////
const Row = (props: RowProps) => {
  const {
    setOpenHash,
    openHash,
    row,
    width,
    index,
    rowHash,
    onSetSubscription,
  } = props;
  const blockchain = BlockChains[row.network];
  const [timeDisplay, setTimeDisplay] = React.useState("");
  const [hour, setHour] = React.useState(0);
  const [minute, setMinute] = React.useState(0);
  const [second, setSecond] = React.useState(0);
  const [HoverIcon, setHoverIcon] = React.useState(false);

  const CwTxn =
    row.l2Tx !== null && row.l2Tx !== undefined ? row.l2Tx.length : 0; //Total Coinweb Transactions in a block
  const opened = openHash === rowHash;
  const size = !row.size ? "N/A" : (row.size / 1024).toFixed(2);
  const ref: any = React.useRef();

  const updateTime = () => {
    const { display, h, m, s } = calculateTime(row.time);
    setTimeDisplay(display);
    setHour(h);
    setMinute(m);
    setSecond(s);
  };
  React.useEffect(() => {
    onSetSubscription(!opened);
  }, [opened, onSetSubscription]);

  // setup some interval timer.
  // we increase the interval for older blocks as we
  // don't show more than seconds, minutes, OR hours.
  const interval = (() => {
    if (hour === 0 && minute === 0) {
      if (second === 0) {
        return 0; // first time
      }
      return 500;
    } else if (hour === 0) {
      return 10000;
    } else {
      return 30000;
    }
  })();
  useInterval(updateTime, interval);

  const handleOpen = () => {
    setOpenHash(opened ? undefined : rowHash);
  };

  const handleRowMouseLeave = () => {
    setHoverIcon(false);
  };

  const formattedTime = React.useMemo(
    () => formatTime(hour, minute, second),
    [hour, minute, second]
  );

  return (
    <React.Fragment>
      {width > 425 ? (
        <DataTableRow
          ref={ref}
          onMouseEnter={() => setHoverIcon(true)}
          onMouseLeave={handleRowMouseLeave}
          className="Table-row"
          sx={{ "& > *": { borderBottom: "unset" } }}
          data-aos="fade-up-custom"
          data-aos-delay={(index + 1) * 100}
          data-aos-anchor="#Block-data-table"
          data-aos-offset="0"
        >
          <DataTableCell component="th" scope="row" align="center">
            <div className="Blockchain-cell-container">
              <div className="Blockchain-icon-container">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleOpen}
                  onMouseEnter={() => setHoverIcon(true)}
                  onMouseLeave={() => setHoverIcon(false)}
                >
                  {opened ? (
                    <div>
                      <img src={UpIcon} alt={"arrow"}></img>
                    </div>
                  ) : (
                    <div className="Down-icon-img">
                      <img
                        className="Down-icon-img"
                        src={HoverIcon ? DownBlueIcon : DownIcon}
                        alt={"arrow"}
                      ></img>
                    </div>
                  )}
                </IconButton>
              </div>
              <div className="Blockchain-cell-container">
                {blockchain.label}
              </div>
            </div>
          </DataTableCell>
          <DataTableCell align="center">{row.height}</DataTableCell>
          <DataTableCell
            sx={{
              textIndent: width > 900 ? "29px!important" : "9px!important",
            }}
            className={opened ? "Row-open" : "Row-th"}
            align="left"
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "5px !important",
                    marginLeft:
                      width > 1280 ? "-39px !important" : "0px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={<div className="Tool-hash-container"> {timeDisplay} </div>}
            >
              <div>{formattedTime}</div>
            </Tooltip>
          </DataTableCell>
          <DataTableCell sx={{ textIndent: "6px!important" }} align="left">
            {size}
          </DataTableCell>
          <DataTableCell sx={{ textIndent: "6px!important" }} align="left">
            {CwTxn} / {row.numberOfTransactions + CwTxn}
          </DataTableCell>
          <DataTableCell sx={{ textIndent: "32px!important" }} align="left">
            {blockchain.currency}{" "}
            {parseFloat(row.amountTransacted.toString()).toFixed(3)}
          </DataTableCell>
          <DataTableCell align="center">
            <CopyIcon data={rowHash} />
          </DataTableCell>
        </DataTableRow>
      ) : (
        <DataTableRow
          onMouseEnter={() => setHoverIcon(true)}
          onMouseLeave={() => setHoverIcon(false)}
          onClick={handleOpen}
          className="Table-row"
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <DataTableCell component="th" scope="row" align="center">
            <div className="Blockchain-cell-container">
              <div className="Blockchain-icon-container">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleOpen}
                  onMouseEnter={() => setHoverIcon(true)}
                  onMouseLeave={() => setHoverIcon(false)}
                >
                  {opened ? (
                    <div>
                      <img src={UpIcon} alt={"arrow"}></img>
                    </div>
                  ) : (
                    <div className="Down-icon-img">
                      <img
                        className="Down-icon-img"
                        src={HoverIcon ? DownBlueIcon : DownIcon}
                        alt={"arrow"}
                      ></img>
                    </div>
                  )}
                </IconButton>
              </div>
              <div className="Blockchain-cell-container">
                {blockchain.label}
              </div>
            </div>
          </DataTableCell>
          <DataTableCell sx={{ textIndent: "12px" }} align="center">
            {row.height}
          </DataTableCell>
          <DataTableCell
            sx={{ padding: "12px 0px 0px 29px !important" }}
            align="left"
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "0px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={
                <div
                  className="Tool-hash-container"
                  style={{ marginTop: "9px" }}
                >
                  {timeDisplay}{" "}
                </div>
              }
            >
              <div>{formattedTime}</div>
            </Tooltip>
          </DataTableCell>
        </DataTableRow>
      )}
      <DataTableCollapseRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <DataCollapse in={opened} timeout="auto" unmountOnExit>
            <CardContainer
              width={width}
              time={timeDisplay}
              displayTime={formattedTime}
              block={row}
              blockchain={blockchain}
            ></CardContainer>
          </DataCollapse>
        </TableCell>
      </DataTableCollapseRow>
    </React.Fragment>
  );
};

export default React.memo(Row);
